

























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AnimatedBurger extends Vue {
  @Prop({ required: true }) burgerStyle!: number | string;

  get open() {
    return this.$store.getters.sideMenuOpen;
  }

  set open(open: boolean) {
    this.$store.commit('SET_SIDE_MENU_OPEN', open);
  }
}
